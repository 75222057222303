<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="advertiser")
			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Advertiser Information <span v-if="advertiser.id">({{ advertiser.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-8
											.form-group
												label Name
												input.form-control(type="text", v-model="advertiser.name")
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Login
									.card-toolbar
								.card-body
									.form-group
										label Email
										input.form-control(type="text", v-model="advertiser.email")
									.form-group
										label Password
										input.form-control.hidden-field(type="password", name="password")
										input.form-control(type="password", v-model="newPass")
										p.help-block Leave blank to keep current value

				b-tab(title="Integrations", style="padding: 0")
					.row
						.col-sm-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Integration
								.card-body
									.form-group
										label Integration Type
										select.form-control(v-model="advertiser.integration_type", @change="selectIntegration()")
											option(:value="null") - Select -
											option(v-for="o in integrationTypeOptions", v-bind:key="o.v", :value="o.v") {{ o.t }}
									.col-sm-6
										.form-group
											label &nbsp;
											div.toggle-wrapper
												b-checkbox(v-model="advertiser.fetch_api") Fetch API

									table.table.table-bordered.table-params
										thead
											tr
												th
													//, @click="addIntegrationParam()"
													button.btn.btn-circle.btn-sm.btn-primary(type="button")
														i.la.la-plus
													| &nbsp;
													span Param
												th Value
												//th &nbsp;
										tbody
											tr(v-for="(p,i) in advertiser.integration_params")
												td {{ p.key }}
												td
													input.form-control(type="text", v-model="p.val")

						.col-sm-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Tracking Link
								.card-body
									table.table.table-bordered.table-params
										thead
											tr
												th
													button.btn.btn-circle.btn-sm.btn-primary(type="button", @click="addTrackingLinkParam()")
														i.la.la-plus
													| &nbsp;
													span Param
												th Value
												th &nbsp;
										tbody
											tr(v-for="(p, i) in advertiser.tracking_link_params")
												td
													input.form-control(type="text", v-model="p.key")
												td
													input.form-control(type="text", v-model="p.val")
												td
													button.btn.btn-sm(type="button", @click="advertiser.tracking_link_params.splice(i,1)")
														i.la.la-times



</template>
<style lang="scss">
.hidden-field {
	opacity: 0; position: absolute;
	pointer-events: none; z-index: -1px;
	width: 1px; height: 1px;
	overflow: hidden;
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

async function loadRoute(vm, to, from, next){

	let integrations = await Vue.$dwData.general.getIntegrations();
	let advertiser = {};
	if (to.name === 'advertiser-edit') {
		let entityId = +to.params.id;
		try {
			advertiser = await Vue.$dwData.advertiser.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load advertiser');
			return next(false);
		}
	} else {
		advertiser = Vue.$dwData.advertiser.newInstance();
	}
	return vm => {
		vm.integrationTypeOptions = integrations;
		vm.advertiser = advertiser;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'AdvertiserForm',
	computed: {
		integration: function (){
			for (let i = 0; i < this.integrationTypeOptions.length; i++) {
				if (this.integrationTypeOptions[i].v === this.advertiser.integration_type) {
					return this.integrationTypeOptions[i];
				}
			}
			return null;
		}
	},
	data(){
		return {
			busy: true,
			advertiser: null,
			newPass: '',
			integrationTypeOptions: [],
		};
	},
	methods: {

		selectIntegration(){
			console.log(this.integration.v);
			this.advertiser.integration_params = [...this.integration.api_params];
			this.advertiser.tracking_link_params = [...this.integration.tracking_params];
		},
		addTrackingLinkParam(){
			this.advertiser.tracking_link_params.push({
				key: '',
				val: ''
			});
		},

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.advertiser);
			data.new_pass = this.newPass;
			try {
				let resp = await this.$api.post('advertisers/save', data);
				this.busy = false;
				this.$notify.success('Advertiser has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'advertiser-list'});
				} else {
					this.advertiser = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Advertisers', name: 'advertiser-list'},
			{title: this.advertiser && this.advertiser.id ? 'Edit Advertiser' : 'Add Advertiser'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'advertiser-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
